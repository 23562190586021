<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const laporanPengeluaran = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/Pengeluaran.vue')
)

const laporanPemasukan = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/Pemasukan.vue')
)

const laporanBuku = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/Buku.vue')
)

const laporanJurnal = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/Jurnal.vue')
)

const laporanBukuBesar = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/BukuBesar.vue')
)

const laporanTrialBalance = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/TrialBalance.vue')
)

const laporanRekapJurnal = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/RekapJurnal.vue')
)
const laporanTransferKas = defineAsyncComponent(() =>
  import('@/components/laporan/keuangan/TransferKas.vue')
)

const auth = useAuthStore()

let laporan = ref('pemasukan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Penerimaan Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pemasukan'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  {
    label: 'Laporan Pengeluaran Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pengeluaran'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
  },
  {
    label: 'Laporan Buku Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'buku'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
  },
  {
    label: 'Laporan Keseluruhan Jurnal',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'jurnal'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  {
    label: 'Laporan Buku Besar',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'buku-besar'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  {
    label: 'Laporan Trial Balance',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'trial-balance'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  {
    label: 'Laporan Rekap Jurnal',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'rekap-jurnal'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  {
    label: 'Laporan Transfer Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'transfer-kas'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
  },
  
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Keuangan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Laporan</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <!--<h3>Pengeluaran</h3>-->
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPengeluaran
            v-if="laporan === 'pengeluaran'"
            @toggle="onToggle"
          />
          <laporanPemasukan v-if="laporan === 'pemasukan'" @toggle="onToggle" />
          <laporanBuku v-if="laporan === 'buku'" @toggle="onToggle" />
          <laporanJurnal v-if="laporan === 'jurnal'" @toggle="onToggle" />
          <laporanBukuBesar v-if="laporan === 'buku-besar'" @toggle="onToggle" />
          <laporanTrialBalance v-if="laporan === 'trial-balance'" @toggle="onToggle" />
          <laporanRekapJurnal v-if="laporan === 'rekap-jurnal'" @toggle="onToggle" />
          <laporanTransferKas v-if="laporan === 'transfer-kas'" @toggle="onToggle" />
        </div>
      </div>
    </div>
  </div>
</template>
